export const routes =
    [
        {
            path: 'admin/', 
            name: 'admin_config',
            meta: {
                module: "mod_admins",
                groups: ['supers']
            },
            component: () => import('@/educap/views/admin.vue')
        }
    ]