import jsPDF from 'jspdf';
import Chart from 'chart.js';
import 'jspdf-autotable';
import moment from 'moment';
import {axiosHelper} from './axios_helper';


class AbstractDocPdf{
    //
    constructor() {
        if (new.target === AbstractDocPdf) {
            throw new Error("AbstractDocPdf es una clase abstracta y no puede ser instanciada directamente.");
        }
        this.doc = new jsPDF();
        this.startY = 0;
        this.anchoLinea = { maxWidth: 170 };
        this.lineHeight = 5;
        this.margin = 20;
        this.headerHeight = 10;
        this.pageWidth = this.doc.internal.pageSize.getWidth();
        this.pageHeight = this.doc.internal.pageSize.getHeight();
        this.contentWidth = this.pageWidth - this.margin*2; // Ancho útil del contenido
        this.startContentY = this.margin + this.headerHeight;
        this.fechaActual = new Date();
    }

    generarGrafico(data) {
        //
        const etiquetas = data.map((value,index) => `Pregunta ${index + 1}`);
        const preguntas_incorrectas = data.map(value => Math.round(value.incorrectas));
        // Crear un canvas con un tamaño mayor
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        
        // Aumentar el tamaño del canvas
        const scaleFactor = 2; // Factor de escala para mejorar la resolución
        canvas.width = 600 * scaleFactor; // 300 * 2
        canvas.height = 400 * scaleFactor; // 200 * 2
    
        // Ajustar el tamaño del canvas para que se vea bien en la pantalla
        canvas.style.width = '600px';
        canvas.style.height = '400px';
    
        const chart = new Chart(ctx, {
            type: 'line',
            data: {
                labels: etiquetas,
                datasets: [{
                    label: 'Respuestas fallidas',
                    data: preguntas_incorrectas,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 0.5,
                    barPercentage: 0.5,  // Ajusta el ancho de la barra dentro de la categoría
                    categoryPercentage: 0.9 // Asegura que la barra use todo el espacio disponible
                }]
            },
            options: {
                responsive: false,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            fontSize: 20, // Aumenta el tamaño de la fuente del eje Y
                            stepSize: 1,  // Solo permite saltos de 1 en 1
                            precision: 0,  // Evita decimales
                            suggestedMin: 0,  // El mínimo siempre es 0
                            suggestedMax: Math.max(...preguntas_incorrectas) + 1, // Asegura que la barra más alta tenga espacio
                            callback: function(value) {
                                return Number.isInteger(value) ? value : ''; // Muestra solo enteros
                            }
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            fontSize: 20 // Aumenta el tamaño de la fuente del eje X
                        }
                    }]
                },
                legend: {
                    labels: {
                        fontSize: 23 // Aumenta el tamaño de la fuente de la leyenda
                    }
                },
                animation: {
                    onComplete: () => {
                        const imgData = canvas.toDataURL('image/png', 1.0); // Calidad máxima
                        return imgData;
                    }
                }
            }
        });
    
        return new Promise((resolve) => {
            setTimeout(() => {
                const imgData = canvas.toDataURL('image/png', 1.0); // Calidad máxima
                const data = {
                    "imgData": imgData,
                    "height": canvas.height,
                    "width": canvas.width
                }
                resolve(data);
            }, 500);
        });
    }

    async ponerGraficoEnPdf(data_for_graph){
        //
        const data = await this.generarGrafico(data_for_graph);
        const imgWidth = 150;
        const imgHeight = (data.height * imgWidth) / data.width;
        this.verificaSiCabeGrafico(imgHeight);
        this.doc.addImage(data.imgData, 'PNG', this.margin, this.startY, imgWidth, imgHeight);
        this.startY += imgHeight + 10;
    }

    /**
     * Converts an image URL to a Base64-encoded string.
     *
     * @param {string} url - The URL of the image to convert.
     * @returns {Promise<string>} A promise that resolves to a Base64-encoded string of the image.
     */
    imageToBase64(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous";
            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL('image/jpeg'));
            };
            img.onerror = reject;
            img.src = url;
        });
    }
    loadImage(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "Anonymous"; // Permite el acceso a imágenes de otro dominio (si es necesario)
            img.src = url;
    
            img.onload = () => {
                resolve(img); // Resuelve la promesa con el HTMLImageElement cargado
            };
    
            img.onerror = (error) => {
                reject(new Error("Error al cargar la imagen: " + error));
            };
        });
    }
    generarPdf() {
        throw new Error('Este método debe ser implementado por una subclase');
    }
    async addHeader(empresa_id) {
        try {
            const pageCount = this.doc.internal.getNumberOfPages();
            const response = await axiosHelper.get("edu/logos/", null, { empresa: empresa_id });
            
            const logoUrl = process.env.VUE_APP_API_URL + response.data.archivo.substring(1);
            const logoImage = await this.loadImage(logoUrl);
            for (let i = 1; i <= pageCount; i++) {
                this.doc.setPage(i);
                this.doc.addImage(logoImage, 'JPEG', this.margin + 150, this.margin / 2, 20, 10);
            }
        } catch (error) {
            console.error("Error al cargar el logo:", error);
        }
    }
    addFooter(){
        const pageCount = this.doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            this.doc.setPage(i);
            this.doc.setFontSize(10);
            this.doc.setFont('helvetica', 'normal');
            const text = `${this.fechaActual} Página ${i} de ${pageCount}`;
            const textWidth = this.doc.getTextWidth(text);
            this.doc.text(text, this.pageWidth - this.margin - textWidth, this.pageHeight - this.margin / 2);
        }
    }
    saveDocument(docName){
        this.doc.save(docName);
    }
    async setTituloDocumento(titulo){
        this.doc.setFontSize(22);
        this.doc.setFont('helvetica', 'bold');
        const tituloY = this.startContentY; // Posición Y del título considerando el margen y el encabezado
        const tit = this.doc.splitTextToSize(titulo, this.pageWidth - this.margin * 2);
        this.doc.text(tit, this.margin, tituloY,this.anchoLinea);
        this.startY = tituloY + (tit.length * 10);
    }
    async setLine(){
        this.doc.setLineWidth(0.1);
        this.doc.line(this.margin, this.startY, this.pageWidth - this.margin, this.startY); // Coordenadas (x1, y1, x2, y2)
        this.startY += 5;
    }
    async setHeaderSection(encabezado){
        this.verificarFinPagina();
        this.setLine();
        this.doc.setFontSize(15);
        this.doc.setTextColor(0, 0, 255); // Cambia el color de la fuente a azul
        this.doc.setFont('helvetica', 'bold');
        this.doc.text(encabezado, this.margin, this.startY);
        this.startY +=1;
        this.setLine();
        this.startY +=4;
        this.doc.setTextColor(0, 0, 0); // Cambia el color de la fuente a negro
    }
    async setHeaderParticipante(encabezado){
        this.verificarFinPagina();
        this.doc.setFontSize(15);
        this.doc.setTextColor(64, 64, 64); // Cambia el color de la fuente a gris
        this.doc.setFont('times', 'bold');
        this.doc.text(encabezado, this.margin, this.startY);
        this.startY +=1;
        this.doc.setDrawColor(128, 128, 128); // Cambia el color de la línea a gris
        this.setLine();
        this.startY +=2;
        this.doc.setTextColor(0, 0, 0);
    }
    async setTituloEvaluacion(encabezado){
        this.verificarFinPagina();
        this.doc.setFontSize(12);
        this.doc.setTextColor(128, 128, 128);
        this.doc.setFont('times', 'bold');
        this.doc.text(encabezado, this.margin, this.startY);
        this.startY +=2;
        this.doc.setTextColor(0, 0, 0); 
    }
    async setMensajeCorto(encabezado){
        this.verificarFinPagina();
        this.doc.setFontSize(12);
        this.doc.setTextColor(0, 0, 0); 
        this.doc.setFont('times', 'normal');
        this.startY +=10;
        this.doc.text(encabezado, this.margin, this.startY);
        this.startY +=20;
    }
    verificarFinPagina(){
        if (this.startY + this.lineHeight > this.pageHeight - this.margin) {
            this.doc.addPage();
            this.startY = this.startContentY;
        }
    }
    
    verificaSiCabeGrafico(altura){
        if (this.startY + altura > this.pageHeight - this.margin) {
            this.doc.addPage();
            this.startY = this.startContentY;
        }
    }
    async escribirTexto(text, negrita=false){
        if(negrita){
            this.doc.setFont("times", "bold");
        }
        const lines = this.doc.splitTextToSize(text, this.contentWidth); // Divide el texto para ajustarse al ancho útil
        let cursorY = this.startY; // Posición inicial en Y
        lines.forEach(line => {
            if (cursorY + this.lineHeight > this.pageHeight - this.margin) {
                this.doc.addPage(); // Salta a una nueva página si se excede el espacio vertical
                cursorY = this.startContentY; // Reinicia el cursor en la nueva página
            }
            this.doc.text(line, this.margin, cursorY);
            cursorY += this.lineHeight;
        });
        this.startY = cursorY;
    }
    // theme can be : 'striped', 'grid' or 'plain'
    async agregarDatosAtabla(body,theme,booleanhavehead,headerTable){
        //
        let configTable = {
            body: body,
            startY: this.startY + 2,
            margin: { top: this.startContentY, left: this.margin, right: this.margin },
            // didDrawPage: this.addHeader,
            headStyles: {
                halign: 'center'
            },
            theme: theme,
            columnStyles: {
                1: { halign: 'right' }
            }
        }
        if(booleanhavehead){
            configTable['head'] = [headerTable];
        }
        this.doc.autoTable(configTable);
        this.startY = this.doc.lastAutoTable.finalY + 10;
    }
    async finalizarDocumento(nombreDocumento, empresa_id){
        await this.addHeader(empresa_id);
        this.addFooter();
        this.saveDocument(nombreDocumento);
    }
} 

class DocPdfRendimientoCursoResumen extends AbstractDocPdf{
    //
    constructor(){ 
        super();
    }
    async addResumenCurso(rendimiento_curso){
        //
        var body = [
            {name:"Nombre Curso",               valor:rendimiento_curso.curso.nombre},
            {name:"Grupo de acceso",            valor:rendimiento_curso.grupo.nombre},
            {name:"Autor del curso",            valor:rendimiento_curso.curso.autor + " (" + rendimiento_curso.curso.gerencia.nombre + ")"},
            {name:"Empresa",                    valor:rendimiento_curso.curso.empresa.nombre},
            {name:"N° Participantes",           valor: rendimiento_curso.cantidad_participantes},
            {name:"N° Evaluaciones",            valor: rendimiento_curso.cantidad_evaluaciones}
        ];
        await this.agregarDatosAtabla(body,'grid',false);
        let nombre_evaluaciones = [];
        if (rendimiento_curso.evaluaciones.length > 0) {
            await this.setHeaderSection("RENDIMIENTO DE EVALUACIONES DEL CURSO");
        
            for (const item of rendimiento_curso.evaluaciones) {
                this.setTituloEvaluacion("Resumen evaluación: " + item.nombre);
        
                nombre_evaluaciones.push(item.nombre);
        
                const body = item.preguntas.map(pregunta => [
                    pregunta.texto,
                    pregunta.incorrectas
                ]);
                const headerTable = ['Pregunta', 'veces fallidas'];
                await this.agregarDatosAtabla(body, 'grid', true, headerTable);
                const data_for_graph = item.preguntas;
                await this.ponerGraficoEnPdf(data_for_graph);
            }
        
            await this.setHeaderSection("RENDIMIENTO DE PARTICIPANTES DEL CURSO");
        
            for (const item of rendimiento_curso.participantes) {
                this.setHeaderParticipante(`${item.nombre} ${item.apellido} (${item.rut})`);
        
                if (item.evaluaciones.length > 0) {
                    for (const evaluacion of item.evaluaciones) {
                        this.setTituloEvaluacion("Evaluación: " + evaluacion.nombre);
                        // redundante: genera la tabla de preguntas fallidas para cada participante.
                        // son las mismas preguntas siempre. dado que ellos hacen una misma evaluacion
                        // const body = evaluacion.preguntas.map(pregunta => [
                        //     pregunta.texto,
                        //     pregunta.incorrectas
                        // ]);
                        // const headerTable = ['Pregunta', 'N° incorrectas'];
                        // await this.agregarDatosAtabla(body, 'grid', true, headerTable);
                        const data_for_graph = evaluacion.preguntas;
                        await this.ponerGraficoEnPdf(data_for_graph);
                    }
                } else {
                    this.setMensajeCorto("     PARTICIPANTE NO HA REALIZADO EVALUACIONES DE ESTE CURSO");
                }
            }
        }        
    }
    async generarPdf(titleDocument, rendimiento_curso){
        this.setTituloDocumento(titleDocument);
        await this.addResumenCurso(rendimiento_curso);
        const documentName = 'Rendimiento_curso_' + rendimiento_curso.curso.nombre + '.pdf';
        const empresa_id = rendimiento_curso.curso.empresa.id;
        await this.finalizarDocumento(documentName.replace(/ /g, "_"), empresa_id);
    }
}

class DocPdfRendimientoCursoInternoIndividual extends AbstractDocPdf{
    //
    constructor(){ 
        super();
    }

    addDatosParticipante(persona){
        this.setHeaderSection("DATOS DEL PARTICIPANTE");
        var body = [
            {name:"Nombre",                 valor:persona.first_name + ' ' + persona.last_name},
            {name:"Rut",                    valor:persona.rut},
            {name:"Cargo",                  valor:persona.gerencia.nombre},
            {name:"Empresa",                valor:persona.empresa.nombre}
        ];
        this.agregarDatosAtabla(body,'grid',false);
    }

    addIndicadoresParticipante(evaluaciones,rendimiento){
        this.setHeaderSection("INDICADORES");
        const  body = [
            ["Evaluaciones realizadas",               String(evaluaciones.rel_aprob.toFixed(2)) + " %"],
            ["Evaluaciones no realizadas",            String(evaluaciones.rel_inv.toFixed(2)) + " %"],
            ["Respuestas correctas",                  String(rendimiento.rel_correctas.toFixed(2)) + " %"],
            ["Respuestas incorrectas",                String(rendimiento.rel_incorrectas.toFixed(2)) + " %"]
        ];
        const headerTable= ['Parámetro', 'Porcentaje'];
        this.agregarDatosAtabla(body,'grid',true,headerTable);
    }

    addEstadoEvaluacionesParticipante(evaluaciones){
        this.setHeaderSection("ESTADO DE LAS EVALUACIONES");
        const body = evaluaciones.logs.map(log => [
            log.evaluacion.nombre,
            log.curso,
            log.is_completado ? moment(log.fecha_inicio).format('DD/MM/YYYY HH:mm:ss') + ' hrs' : '',
            log.is_completado ? moment(log.fecha_termino).format('DD/MM/YYYY HH:mm:ss') + ' hrs': '',
            log.is_completado ? 'completado':'pendiente'
        ]);
        const headerTable = ['Evaluación', 'Curso','Inicio','Término','estado'];
        this.agregarDatosAtabla(body,'grid',true,headerTable);

    }

    addTiempoRespuestaParticipante(tiempo){
        this.setHeader("TIEMPO DE RESPUESTA");
        if(tiempo.logs.length > 0){
            let body = [];
            tiempo.logs.forEach((item,index)=>{
                const tiempo_data = item.puntajes.map(curso => [
                    curso.curso,
                    moment(curso.finalizado).format("DD/MM/YYYY HH:mm:ss"),
                    curso.tag
                ]);
                body.push(...tiempo_data);
            });
            const headerTable = ['Curso','Realización','estado'];
            this.agregarDatosAtabla(body,'grid',true,headerTable);
        }
    }

    async addDetallePorCurso(rendimiento){
        //
        this.setHeaderSection("DETALLE POR EVALUACIONES COMPLETADAS");
        if(rendimiento.logs.length > 0) {
            for (const item of rendimiento.logs) {
                const grupoAcceso = item.grupo;
                // itera sobre las evaluaciones de cada grupo contenidas en registro
                var registros = item.registros;
                for (const registro of registros) {
                    this.escribirTexto("Evaluación: "+registro.evaluacion.nombre,true);
                    var body = [
                        {name:"Curso",                  valor:registro.evaluacion.curso},
                        {name:"Grupo de acceso",        valor:grupoAcceso},
                        {name:"Cantidad de preguntas",  valor:registro.respuestas.length},
                        {name:"Total desaciertos",      valor:registro.total_incorrectas},
                        {name:"Tiempo (HH:MM:SS)",      valor:registro.duracion.split(".")[0]},
                    ];
                    this.agregarDatosAtabla(body,'grid',false);
                    const body2 = registro.respuestas.map(respuesta => [
                        respuesta.pregunta__texto,
                        respuesta.incorrectas
                    ]);
                    const headerTable = ['Pregunta', 'Desaciertos'];
                    this.agregarDatosAtabla(body2,'striped',true,headerTable);
                    const data_for_graph = registro.respuestas;
                    await this.ponerGraficoEnPdf(data_for_graph);
                }
            }
        }
    }

    async generarPdf(titleDocument, data){
        //
        this.setTituloDocumento(titleDocument);
        this.addDatosParticipante(data.persona);
        this.addIndicadoresParticipante(data.evaluaciones,data.rendimiento);
        this.addEstadoEvaluacionesParticipante(data.evaluaciones);
        await this.addDetallePorCurso(data.rendimiento);
        const documentName = data.persona.first_name + '_' + data.persona.last_name + '_' + data.persona.rut + '.pdf';
        const empresa_id = data.persona.empresa.id;
        await this.finalizarDocumento(documentName.replace(/ /g, "_"), empresa_id);
    }
}

export {DocPdfRendimientoCursoInternoIndividual,DocPdfRendimientoCursoResumen}