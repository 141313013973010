import Vue from 'vue'
import store from '@/store'
import VueRouter from 'vue-router'
import Login from '@/educap/views/login'
import Logout from '@/educap/views/logout'
import Dashboard from '@/educap/views/dashboard'
import Page404 from '@/shared/views/page_404'

import { routes as routes_cap } from '@/educap/router/routes.capacitacion'
import { routes as routes_eval } from '@/educap/router/routes.evaluacion'
import { routes as routes_users } from '@/educap/router/routes.users'
import { routes as routes_groups } from '@/educap/router/routes.groups'
import { routes as routes_others } from '@/educap/router/routes.others'
import { routes as routes_cursos_externos } from '@/educap/router/routes.cursos_externos'
import { routes as routes_admin } from '@/educap/router/routes.admin'

Vue.use(VueRouter)

const routes = [
  {
    path: "*",
    component: Page404
  },
  {
    path: '',
    redirect: {
      name: 'login'
    }
  },
  {
    path: '/login',
    name: 'login',
    meta: { session: true },
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Logout
  },
  {
    path: '/dashboard',
    meta: { auth: true },
    component: Dashboard,
    children: [
      {
        path: '',
        name: 'dashboard_index',
        component: () => import('@/educap/views/dashboard/index')
      },
      ...routes_users,
      ...routes_groups,
      ...routes_cap,
      ...routes_eval,
      ...routes_others,
      ...routes_cursos_externos,
      ...routes_admin
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach(async (to, from, next) => {
  const token = localStorage.getItem('token');

  if (to.matched.some(record => record.meta.session)) {
    if (token) {
      next({ name: 'dashboard_index' });
      return;
    }
  }

  if (to.matched.some(record => record.meta.auth)) {
    if (!token) {
      next({ name: 'login' });
      return;
    }

    //En caso que no se admita el tipo de usuario de acuerdo a lo almacenado --> Dashboard Index
    const user = store.state.auth.user;
    if (!!to.meta.groups && !to.meta.groups.some(g => user.groups.includes(g))) {

      console.log('Esta sección no es accesible para este tipo de usuario.');
      next({ name: 'dashboard_index' });
      return;
    }

    next();
    return;
  }
  next();
});

export default router;